import * as actionType from '../constants/constant.action'

export const findCare = (reqObj) => ({
    type: actionType.FIND_CARE_REQUEST,
    payload: reqObj,
  });

export const findCareMore = (reqObj) => ({
    type: actionType.FIND_CARE_MORE_REQUEST,
    payload: reqObj,
});

export const findCareHasMoreData = (reqObj) => ({
  type: actionType.FIND_CARE_MORE_HAS_DATA,
  payload: reqObj,
});

export const findCareLoad = (reqObj) => ({
  type: actionType.FIND_CARE_LOAD,
  payload: reqObj,
});

export const findCareClose = (reqObj) => ({
  type: actionType.FIND_CARE_CLOSE,
  payload: reqObj,
});

export const findCareClearSearch = (reqObj) => ({
  type: actionType.FIND_CARE_CLEAR_SEARCH,
  payload: reqObj,
});

export const findCareSetState = (reqObj) => ({
  type: actionType.FIND_CARE_SET_STATE,
  payload: reqObj,
});

export const findCareSetSearchCriteria = (reqObj) => ({
  type: actionType.FIND_CARE_SET_SEARCH_CRITERIA,
  payload: reqObj,
});

export const findCareSetUiState = (reqObj) => ({
  type: actionType.FIND_CARE_SET_UI_STATE,
  payload: reqObj,
});