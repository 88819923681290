import createSagaMiddleware, { END } from 'redux-saga';
import sagaMonitor from '@redux-saga/simple-saga-monitor';
import { reducers } from '../reducers';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import thunk from "redux-thunk";

const confStore = initialState => {
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

  const middleware = [
    ...getDefaultMiddleware(),
    sagaMiddleware,
    thunk
  ]

  const store = configureStore({
    reducer: reducers,
    preloadedState: initialState,
    middleware
  });

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return store;
};

export default confStore;
