import { sortServiceLines, sortSpecialties } from 'utils/serviceLinesUtils';
import * as actionType from '../constants/constant.action';

const defaultState = {
  referralsData: null,
  referralError: null,
  memberId: null,
  createReferralsFailure: null,
  createReferralsSuccess: null,
  isPcpReferralAvailable: false,
  serviceLines: {
    healthProfessionals: [],
    facilities: []
  },
  specialties: [],
  workflowRequest: false,
  referralWorkflow: null,
  referralTool: null,
  pcploading: false
};

const referralDetails = (state = defaultState, action) => {
  switch (action.type) {
    case actionType.SET_REFERRAL_DATA:
      return Object.assign({}, state, {
        referralsData: action.payload.referrals.resultObject.referrals
      });

    case actionType.SET_REFERRAL_ERROR:
      return Object.assign({}, state, {
        referralError: action.payload.referralError
      });

    case actionType.SET_DEFAULT_STATE_IN_REDUCER:
      return Object.assign({}, defaultState, {
        memberId: action.payload.memberId
      });

    case actionType.SET_REFERRALS_FAILURE:
      return Object.assign({}, defaultState, {
        createReferralsFailure: action.payload
      });

    case actionType.SET_REFERRALS_SUCCESS:
      return Object.assign({}, defaultState, {
        createReferralsSuccess: action.payload
      });

    case actionType.CLEAR_REFERRALS_STATUS:
      return {
        ...state,
        createReferralsSuccess: null,
        createReferralsFailure: null
      }

    case actionType.GET_PCP_REFERRALS_AVAILABLE:
      return Object.assign({}, defaultState, {
        isPcpReferralAvailable: action.payload.isPcpReferralAvailable
      });

    case actionType.GET_REFERRALS_SUCCESS:
      return {
        ...state,
        referralsData: action.payload
      }
    case actionType.GET_REFERRALS_FAILURE:
      return {
        ...state,
        referralsData: []
      }

    case actionType.SERVICE_LINES_SET_LOADING:
      return {
        ...state,
        pcploading: action.payload.pcploading
      };


    case actionType.GET_SERVICE_LINES_SUCCESS:
      const sortedServiceLines = sortServiceLines(action.payload.serviceLineList)

      const mapsServiceLine = (serviceLine) => ({
        label: serviceLine.name,
        value: {
          name: serviceLine.name,
          isPcp: serviceLine.isPcp,
        }
      })

      const healthProfessionalsServiceLines = sortedServiceLines.filter(servLine => servLine.isCSRDoctor).map(mapsServiceLine)
      const facilitiesServiceLines = sortedServiceLines.filter(servLine => servLine.isCSRFacility).map(mapsServiceLine)

      const mapSpecialty = (specialty) => ({
        label: specialty.specialty,
        value: specialty.code,
      })
      const sortedSpecialties = sortSpecialties(action.payload.specialtyList).map(mapSpecialty)

      return {
        ...state,
        serviceLines: {
          healthProfessionals: healthProfessionalsServiceLines,
          facilities: facilitiesServiceLines
        },
        specialties: sortedSpecialties,
        workflowRequest: false,
        pcploading: false,
        referralWorkflow: action.payload.referralWorkflow,
        referralTool: action.payload.referralTool
      }

    case actionType.GET_SERVICE_LINES_FAILURE:
      return {
        ...state,
        pcploading: false,
        serviceLines: action.payload,
        specialties: [],
      }

    default:
      return state;
  }
};

export default referralDetails;
