import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  doctorServiceLines: {
    items: [] as any[],
    status: 'empty',
  },
  facilityServiceLines: {
    items: [] as any[],
    status: 'empty',
  },
  practitionerServiceLines: {
    items: [] as any[],
    status: 'empty',
  }
}

export const serviceLinesSlice = createSlice({
  name: 'serviceLines',
  initialState,
  reducers: {
    reset: () => initialState,
    loadDoctorServiceLines: (state) => {
      state.doctorServiceLines.status = 'loading'
    },
    loadFacilityServiceLines: (state) => {
      state.facilityServiceLines.status = 'loading'
    },
    setDoctorServiceLines: (state, action: PayloadAction<any[]>) => {
      state.doctorServiceLines.items = action.payload
      state.doctorServiceLines.status = 'loaded'
    },
    setFacilityServiceLines: (state, action: PayloadAction<any[]>) => {
      state.facilityServiceLines.items = action.payload
      state.facilityServiceLines.status = 'loaded'
    }
  },
});