import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchDoctorsByServiceLines, fetchDoctorsByServiceLinesAndName, fetchPractitionersByNpi } from './thunks.async'
import getDistance from 'geolib/es/getDistance'
import convertDistance from 'geolib/es/convertDistance'
import { _global } from '../../helpers'

const providersAdapter = createEntityAdapter()

const initialState = providersAdapter.getInitialState({
  providers: {
    items: [] as any[],
    status: 'empty',
  },
  practitioners: {
    items: [] as any[],
    status: 'empty',
  },
  provider: {
    item: {} as any,
    status: 'empty',
  },
})

export const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    reset: () => initialState,
    loadProvider: (state) => {
      state.provider.status = 'loading'
    },
    setProvider: (state, action: PayloadAction<any>) => {
      state.provider.item = action.payload
      state.provider.status = 'loaded'
    },
    clearProvider: (state) => {
      state.provider.item = {}
      state.provider.status = 'empty'
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPractitionersByNpi.pending, (state) => {
        state.practitioners.status = 'loading'
      })
      .addCase(fetchPractitionersByNpi.fulfilled, (state, action) => {
        state.practitioners.status = 'loaded'
        state.practitioners.items = action.payload?.filter((practitioner: any) => !(_global.isTerminatedProvidersAndFacilities(practitioner.endDate)))
        state.providers.status = 'empty'
        state.providers.items = []
      })
      .addCase(fetchPractitionersByNpi.rejected, (state) => {
        state.practitioners.status = 'empty'
        state.practitioners.items = []
      })
      .addCase(fetchDoctorsByServiceLines.pending, (state) => {
        state.providers.status = 'loading'
      })
      .addCase(fetchDoctorsByServiceLines.fulfilled, (state, action) => {
        state.providers.status = 'loaded'
        state.providers.items = action.payload
      })
      .addCase(fetchDoctorsByServiceLines.rejected, (state) => {
        state.providers.status = 'empty'
        state.providers.items = []
      })
      .addCase(fetchDoctorsByServiceLinesAndName.pending, (state) => {
        state.providers.status = 'loading'
      })
      .addCase(fetchDoctorsByServiceLinesAndName.fulfilled, (state, action) => {
        const [coordinates, data] = action.payload
        state.practitioners.status = 'loaded'
        state.practitioners.items = data 
          ? data.map((item: any) => {
              return { 
                ...item,
                distance: convertDistance(getDistance(coordinates, { lat: item.lat, lon: item.long }), 'mi').toFixed(2) 
              }
            })
          : []
        state.providers.status = 'empty'
        state.providers.items = []
      })
      .addCase(fetchDoctorsByServiceLinesAndName.rejected, (state) => {
        state.providers.status = 'empty'
        state.providers.items = []
      })
  },
})
