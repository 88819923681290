import React, { useEffect } from 'react'
import { Auth, Cache } from 'aws-amplify'
import Logo from '../../assets/images/logo.png'
import LoginForm from '../../components/loginForm'
import { useHistory } from 'react-router'
import './style/style.scss'

const Admin = () => {
  const history = useHistory()

  const getUserInfo = user => {
    sessionStorage.removeItem('isLoggedIn')
    Cache.clear()
    Auth.currentSession()
      .then(data => {
        sessionStorage.setItem('accessToken', data.accessToken.jwtToken)
        sessionStorage.setItem('idToken', data.accessToken.jwtToken)
        sessionStorage.setItem('isLoggedIn', 'true')
        sessionStorage.setItem('userSignedIn', 'true')
        history.push('/member-search')
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('idToken')
    sessionStorage.removeItem('isLoggedIn')
    sessionStorage.removeItem('userSignedIn')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="login-container">
      <div className="logo">
        <img src={Logo} alt="" className="logo-navbar" />
      </div>
      <LoginForm getUserInfo={getUserInfo} />
    </div>
  )
}

export default Admin
