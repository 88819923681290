export const GET_LOGIN_REQUEST = 'GET_LOGIN_REQUEST';
export const GET_LOGIN_SUCCESS = 'GET_LOGIN_SUCCESS';
export const GET_LOGIN_FAILURE = 'GET_LOGIN_FAILURE';

export const FIND_MEMBER_REQUESTED = 'FIND_MEMBER_REQUESTED'
export const FIND_MEMBER_SUCCESS = 'FIND_MEMBER_SUCCESS'
export const FIND_MEMBER_FAILURE = 'FIND_MEMBER_FAILURE'
export const CLEAR_MEMBER_SEARCH = 'CLEAR_MEMBER_SEARCH'

export const GET_MEMBER_INFO_REQUEST = 'GET_MEMBER_INFO_REQUEST'
export const GET_MEMBER_INFO_SUCCESS = 'GET_MEMBER_INFO_SUCCESS'
export const GET_MEMBER_INFO_FAILURE = 'GET_MEMBER_INFO_FAILURE'
export const TOGGLE_GET_MEMBER_INFO_SUCCESS = 'TOGGLE_GET_MEMBER_INFO_SUCCESS'

export const UPDATE_MEMBER_MESSAGE_REQUEST = 'UPDATE_MEMBER_MESSAGE_REQUEST'
export const UPDATE_MEMBER_MESSAGE_SUCCESS = 'UPDATE_MEMBER_MESSAGE_SUCCESS'
export const UPDATE_MEMBER_MESSAGE_FAILURE = 'UPDATE_MEMBER_MESSAGE_FAILURE'

export const GET_MEMBER_CARE_INFO_REQUEST = 'GET_MEMBER_CARE_INFO_REQUEST'
export const GET_MEMBER_CARE_INFO_SUCCESS = 'GET_MEMBER_CARE_INFO_SUCCESS'
export const GET_MEMBER_CARE_INFO_FAILURE = 'GET_MEMBER_CARE_INFO_FAILURE'

export const GET_REFERRALS_REQUEST = 'GET_REFERRALS_REQUEST'
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS'
export const GET_REFERRALS_FAILURE = 'GET_REFERRALS_FAILURE'

export const GET_MEMBER_INBOX_MESSAGES_REQUEST = 'GET_MEMBER_INBOX_MESSAGES_REQUEST'
export const GET_MEMBER_INBOX_MESSAGES_SUCCESS = 'GET_MEMBER_INBOX_MESSAGES_SUCCESS'
export const GET_MEMBER_INBOX_MESSAGES_FAILURE = 'GET_MEMBER_INBOX_MESSAGES_FAILURE'

export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE'

export const GET_OUTBOX_MESSAGES_REQUEST = 'GET_OUTBOX_MESSAGES_REQUEST'
export const GET_OUTBOX_MESSAGES_SUCCESS = 'GET_OUTBOX_MESSAGES_SUCCESS'
export const GET_OUTBOX_MESSAGES_FAILURE = 'GET_OUTBOX_MESSAGES_FAILURE'

export const GET_COMPLETE_MESSAGES_REQUEST = 'GET_COMPLETE_MESSAGES_REQUEST'
export const GET_COMPLETE_MESSAGES_SUCCESS = 'GET_COMPLETE_MESSAGES_SUCCESS'
export const GET_COMPLETE_MESSAGES_FAILURE = 'GET_COMPLETE_MESSAGES_FAILURE'

export const GET_MESSAGE_TAB_INFO_REQUEST = 'GET_MESSAGE_TAB_INFO_REQUEST'
export const GET_MESSAGE_TAB_INFO_SUCCESS = 'GET_MESSAGE_TAB_INFO_SUCCESS'
export const GET_MESSAGE_TAB_INFO_FAILURE = 'GET_MESSAGE_TAB_INFO_FAILURE'

export const UPDATE_MEMBER_INFO_REQUEST = 'UPDATE_MEMBER_INFO_REQUEST'
export const UPDATE_MEMBER_INFO_SUCCESS = 'UPDATE_MEMBER_INFO_SUCCESS'
export const UPDATE_MEMBER_INFO_FAILURE = 'UPDATE_MEMBER_INFO_FAILURE'

export const REPLY_MEMBER_MESSAGE_REQUEST = 'REPLY_MEMBER_MESSAGE_REQUEST'
export const REPLY_MEMBER_MESSAGE_SUCCESS = 'REPLY_MEMBER_MESSAGE_SUCCESS'
export const REPLY_MEMBER_MESSAGE_FAILURE = 'REPLY_MEMBER_MESSAGE_FAILURE'

export const GET_DEPENDENT_LIST_REQUEST = 'GET_DEPENDENT_LIST_REQUEST'
export const GET_DEPENDENT_LIST_SUCCESS = 'GET_DEPENDENT_LIST_SUCCESS'
export const GET_DEPENDENT_LIST_FAILURE = 'GET_DEPENDENT_LIST_FAILURE'

export const GET_MEMBER_COMPLETE_MESSAGES_REQUEST = 'GET_MEMBER_COMPLETE_MESSAGES_REQUEST'
export const GET_MEMBER_COMPLETE_MESSAGES_SUCCESS = 'GET_MEMBER_COMPLETE_MESSAGES_SUCCESS'
export const GET_MEMBER_COMPLETE_MESSAGES_FAILURE = 'GET_MEMBER_COMPLETE_MESSAGES_FAILURE'

export const GET_MEMBER_OUTBOX_MESSAGES_REQUEST = 'GET_MEMBER_OUTBOX_MESSAGES_REQUEST'
export const GET_MEMBER_OUTBOX_MESSAGES_SUCCESS = 'GET_MEMBER_OUTBOX_MESSAGES_SUCCESS'
export const GET_MEMBER_OUTBOX_MESSAGES_FAILURE = 'GET_MEMBER_OUTBOX_MESSAGES_FAILURE'

export const GET_ALL_TEMPLATES_REQUEST = 'GET_ALL_TEMPLATES_REQUEST';
export const GET_ALL_TEMPLATES_SUCCESS = 'GET_ALL_TEMPLATES_SUCCESS';
export const GET_ALL_TEMPLATES_FAILURE = 'GET_ALL_TEMPLATES_FAILURE';

export const FIND_MASTER_PLANS_REQUEST = 'FIND_MASTER_PLANS_REQUEST';
export const FIND_MASTER_PLANS_SUCCESS = 'FIND_MASTER_PLANS_SUCCESS';
export const FIND_MASTER_PLANS_FAILURE = 'FIND_MASTER_PLANS_FAILURE';

export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_FAILURE = 'CREATE_MESSAGE_FAILURE';

export const RESET_CONFIRMATION_MODAL = 'RESET_CONFIRMATION_MODAL';

export const GET_MEMBERS_BY_FAMILY_ID_REQUEST = 'GET_MEMBERS_BY_FAMILY_ID_REQUEST';
export const GET_MEMBERS_BY_FAMILY_ID_SUCCESS = 'GET_MEMBERS_BY_FAMILY_ID_SUCCESS';
export const GET_MEMBERS_BY_FAMILY_ID_FAILURE = 'GET_MEMBERS_BY_FAMILY_ID_FAILURE';

export const UPDATE_MESSAGE_REQUEST = 'UPDATE_MESSAGE_REQUEST';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_FAILURE = 'UPDATE_MESSAGE_FAILURE';

export const REPLY_MESSAGE_REQUEST = 'REPLY_MESSAGE_REQUEST';
export const REPLY_MESSAGE_SUCCESS = 'REPLY_MESSAGE_SUCCESS';
export const REPLY_MESSAGE_FAILURE = 'REPLY_MESSAGE_FAILURE';

export const RESET_ACCOUNT_REQUEST = 'RESET_ACCOUNT_REQUEST';
export const RESET_ACCOUNT_SUCCESS = 'RESET_ACCOUNT_SUCCESS';
export const RESET_ACCOUNT_FAILURE = 'RESET_ACCOUNT_FAILURE';

export const SEARCH_PCP_REQUEST = 'SEARCH_PCP_REQUEST';
export const SEARCH_PCP_SUCCESS = 'SEARCH_PCP_SUCCESS';
export const SEARCH_PCP_FAILURE = 'SEARCH_PCP_FAILURE';
export const CLEAR_SEARCH_PCP_ERROR = 'CLEAR_SEARCH_PCP_ERROR';
export const DEPENDENT_TOGGLE_REQUEST = 'DEPENDENT_TOGGLE_REQUEST';

export const DEPENDENT_TOGGLE_PROFILE_REQUEST = 'DEPENDENT_TOGGLE_PROFILE_REQUEST';
export const DEPENDENT_TOGGLE_PROFILE_SUCCESS = 'DEPENDENT_TOGGLE_PROFILE_SUCCESS';
export const DEPENDENT_TOGGLE_PROFILE_FAILURE = 'DEPENDENT_TOGGLE_PROFILE_FAILURE';


export const SWITCH_MESSAGE_TAB = 'SWITCH_MESSAGE_TAB';

export const CLEAR_MEMBER_SEARCH_RESULT = 'CLEAR_MEMBER_SEARCH_RESULT';

export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';


export const SET_LOADING = 'SET_LOADING';
export const SET_CALLER_MEMBERID = 'SET_CALLER_MEMBERID';
export const SET_MEMBER_MEMBERID = 'SET_MEMBER_MEMBERID';
export const GET_TAB_INFO = 'GET_TAB_INFO';
export const RESET_TAB_INFO = 'RESET_TAB_INFO';
export const SET_MEMBER_DETAILS = 'SET_MEMBER_DETAILS';
export const SET_CALLER_DETAILS = 'SET_CALLER_DETAILS';
export const SET_MEMBER_PROFILE = 'SET_MEMBER_PROFILE';
export const SET_CARE_DETAILS = 'SET_CARE_DETAILS';
export const SET_ACTIVATION_DETAILS = 'SET_ACTIVATION_DETAILS';
export const SET_MEMBER_ACCESS_ON_DEPENDENT = 'SET_MEMBER_ACCESS_ON_DEPENDENT';
export const SET_SERCHED_MEMBERS_LIST = 'SET_SERCHED_MEMBERS_LIST';
export const SET_SERVER_SUCCESS = 'SET_SERVER_SUCCESS';
export const SET_SERVER_ERROR = 'SET_SERVER_ERROR';
export const SET_CLEAR_FORM = 'SET_CLEAR_FORM';
export const SET_MEMBER_DEPENDENTS = 'SET_MEMBER_DEPENDENTS';
export const RESET_DEPENDENT_LIST = 'RESET_DEPENDENT_LIST';
export const UPDATE_TAB_INFO = 'UPDATE_TAB_INFO';
export const SET_MESSAGE_DETAILS = 'SET_MESSAGE_DETAILS';
export const SET_MEMBER_MESSAGE_DETAILS = 'SET_MEMBER_MESSAGE_DETAILS';
export const RESET_MEMBER_DETAILS_REDUCER = 'RESET_MEMBER_DETAILS_REDUCER';
export const RESET_MEMBER_DEPENDENTS_REDUCER =
  'RESET_MEMBER_DEPENDENTS_REDUCER';
export const SET_DEFAULT_STATE_IN_REDUCER = 'SET_DEFAULT_STATE_IN_REDUCER';
export const SET_TEMPLATES_NAME = 'SET_TEMPLATES_NAME';
export const SET_TEMPLATE_LIST = 'SET_TEMPLATE_LIST';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_SELECTED_MEMBERS_COUNT = 'SET_SELECTED_MEMBERS_COUNT';
export const SET_MEMBERS_LIST_BY_FAMILYID = 'SET_MEMBERS_LIST_BY_FAMILYID';
export const SET_INBOX_MESSAGE_DETAILS = 'SET_INBOX_MESSAGE_DETAILS';
export const SET_MEMBER_INBOX_MESSAGE_DETAILS =
  'SET_MEMBER_INBOX_MESSAGE_DETAILS';
export const SET_FAMILY_ID = 'SET_FAMILY_ID';
export const SET_REFERRAL_ERROR = 'SET_REFERRAL_ERROR';
export const SET_REFERRAL_DATA = 'SET_REFERRAL_DATA';
export const SET_NETWORK_ERROR_DEPENDENT_API =
  'SET_NETWORK_ERROR_DEPENDENT_API';
export const UPDATE_MEMBER_INFO = 'UPDATE_MEMBER_INFO';
export const UPDATE_WITH_NEW_MEMBER_INFO = 'UPDATE_WITH_NEW_MEMBER_INFO';
export const NEW_FIELD_UPDATE_ERROR = 'NEW_FIELD_UPDATE_ERROR';
export const NEW_FIELD_UPDATE_SUCCESS = 'NEW_FIELD_UPDATE_SUCCESS';
export const NEW_UPDATE_PHONE_NUMBER = 'NEW_UPDATE_PHONE_NUMBER';
export const NEW_UPDATE_EMAIL_ADDRESS = 'NEW_UPDATE_EMAIL_ADDRESS';
export const NEW_UPDATE_PAPERLESS_PREFERENCE = 'NEW_UPDATE_PAPERLESS_PREFERENCE';
export const NEW_UPDATE_EMAIL_PREFERENCE = 'NEW_UPDATE_EMAIL_PREFERENCE';
export const NEW_UPDATE_SMS_PREFERENCE = 'NEW_UPDATE_SMS_PREFERENCE';
export const UPDATE_SUCCESS_MSG = 'UPDATE_SUCCESS_MSG';
export const PCP_SET_LOADING = 'PCP_SET_LOADING';
export const SERVICE_LINES_SET_LOADING = 'SERVICE_LINES_SET_LOADING';

export const SEARCH_PCP = 'SEARCH_PCP';
export const UPDATE_PCP = 'UPDATE_PCP';
export const UPDATE_PCP_DETAILS = 'UPDATE_PCP_DETAILS';
export const SET_OPEN_CHANGE_PCP_MODAL = 'SET_OPEN_CHANGE_PCP_MODAL';
export const SET_DEPENDENT_TOGGLE = 'SET_DEPENDENT_TOGGLE';
export const SEARCH_PCP_LIST_FAILURE = 'SEARCH_PCP_LIST_FAILURE';
export const SET_PCP_LIST_FAILURE = 'SET_PCP_LIST_FAILURE';
export const SEARCH_PCP_LIST_ZERO = 'SEARCH_PCP_LIST_ZERO';
export const RESET_ACCOUNT = 'RESET_ACCOUNT';

export const SYNC_FAMILY_CLAIM_LIST_START = 'SYNC_FAMILY_CLAIM_LIST_START';
export const SYNC_FAMILY_CLAIM_LIST_SUCCESS = 'SYNC_FAMILY_CLAIM_LIST_SUCCESS';
export const SYNC_FAMILY_CLAIM_LIST_FAILURE = 'SYNC_FAMILY_CLAIM_LIST_FAILURE';
export const SYNC_FAMILY_CLAIM_LIST_RESET = 'SYNC_FAMILY_CLAIM_LIST_RESET';

export const GET_SYNC_STATUS_START = 'GET_SYNC_STATUS_START';
export const GET_SYNC_STATUS_SUCCESS = 'GET_SYNC_STATUS_SUCCESS';
export const GET_SYNC_STATUS_FAILURE = 'GET_SYNC_STATUS_FAILURE';

export const GET_PCP_REFERRALS_AVAILABLE = 'GET_PCP_REFERRALS_AVAILABLE';

export const REPLY_MESSAGE_LIST_SUCCESS = 'REPLY_MESSAGE_LIST_SUCCESS';
export const REPLY_MESSAGE_LIST_FAILURE = 'REPLY_MESSAGE_LIST_FAILURE';

export const RETRIEVE_REPLY_MESSAGE_SUCCESS = 'RETRIEVE_REPLY_MESSAGE_SUCCESS';
export const RETRIEVE_REPLY_MESSAGE_FAILURE = 'RETRIEVE_REPLY_MESSAGE_FAILURE';

export const RETRIEVE_SINGLE_REPLY_MESSAGE_SUCCESS =
  'RETRIEVE_SINGLE_REPLY_MESSAGE_SUCCESS';
export const RETRIEVE_SINGLE_REPLY_MESSAGE_FAILURE =
  'RETRIEVE_SINGLE_REPLY_MESSAGE_FAILURE';
export const GET_RESOURCES_REQUEST = 'GET_RESOURCES_REQUEST';
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';
export const GET_RESOURCES_FAILURE = 'GET_RESOURCES_FAILURE';

export const GET_SERVICE_LINES_REQUEST = 'GET_SERVICE_LINES_REQUEST';
export const GET_SERVICE_LINES_SUCCESS = 'GET_SERVICE_LINES_SUCCESS';
export const GET_SERVICE_LINES_FAILURE = 'GET_SERVICE_LINES_FAILURE';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';

// Messages

//FamilyTabs

export const UPDATE_ACCESS_APPROVAL_REQUEST = 'UPDATE_ACCESS_APPROVAL_REQUEST';
export const UPDATE_ACCESS_APPROVAL_SUCCESS = 'UPDATE_ACCESS_APPROVAL_SUCCESS';
export const UPDATE_ACCESS_APPROVAL_FAILURE = 'UPDATE_ACCESS_APPROVAL_FAILURE';



export const SELECT_MEMBER_REQUEST = 'SELECT_MEMBER_REQUEST';
export const SELECT_MEMBER_SUCCESS = 'SELECT_MEMBER_SUCCESS';
export const SELECT_MEMBER_FAILURE = 'SELECT_MEMBER_SUCCESS';



export const GET_FAMILY_MEMBER_REQUEST = 'GET_FAMILY_MEMBER_REQUEST';
export const GET_FAMILY_MEMBER_SUCCESS = 'GET_FAMILY_MEMBER_SUCCESS';
export const GET_FAMILY_MEMBER_FAILURE = 'GET_FAMILY_MEMBER_FAILURE';

//mailing address update 
export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

export const UPDATE_MEMBER_STATUS_REQUEST = 'UPDATE_MEMBER_STATUS_REQUEST';
export const UPDATE_MEMBER_STATUS_SUCCESS = 'UPDATE_MEMBER_STATUS_SUCCESS';
export const UPDATE_MEMBER_STATUS_FAILURE = 'UPDATE_MEMBER_STATUS_FAILURE';

export const SET_REFERRALS_REQUEST = 'SET_REFERRALS_REQUEST';
export const SET_REFERRALS_SUCCESS = 'SET_REFERRALS_SUCCESS';
export const SET_REFERRALS_FAILURE = 'SET_REFERRALS_FAILURE';
export const CLEAR_REFERRALS_STATUS = 'CLEAR_REFERRALS_STATUS';

export const SELECT_PCP = 'SELECT_PCP';

export const PCP_UPDATE_REQUEST = 'PCP_UPDATE_REQUEST';
export const PCP_UPDATE_SUCCESS = 'PCP_UPDATE_SUCCESS';
export const PCP_UPDATE_FAILURE = 'PCP_UPDATE_FAILURE';


export const PCP_UPDATE_ACTIVATION_REQUEST = 'PCP_UPDATE_ACTIVATION_REQUEST';

export const TOGGLE_ADDRESS_MODAL = 'TOGGLE_ADDRESS_MODAL';

export const NEW_MESSAGE_REQUEST = 'NEW_MESSAGE_REQUEST';
export const NEW_MESSAGE_SUCCESS = 'NEW_MESSAGE_SUCCESS';
export const NEW_MESSAGE_FAILURE = 'NEW_MESSAGE_FAILURE';

export const CLEAR_MEMBER_DATA = 'CLEAR_MEMBER_DATA';

export const GET_PROFILE_TAB_REQUEST = 'GET_PROFILE_TAB_REQUEST'
export const GET_PROFILE_TAB_SUCCESS = 'GET_PROFILE_TAB_SUCCESS'
export const GET_PROFILE_TAB_FAILURE = 'GET_PROFILE_TAB_FAILURE'

export const VERIFY_ELIGIBILITY_REQUEST = 'VERIFY_ELIGIBILITY_REQUEST';
export const VERIFY_ELIGIBILITY_SUCCESS = 'VERIFY_ELIGIBILITY_SUCCESS';
export const VERIFY_ELIGIBILITY_FAILURE = 'VERIFY_ELIGIBILITY_FAILURE';

export const CHECK_USERNAME_REQUEST = 'CHECK_USERNAME_REQUEST';
export const CHECK_USERNAME_SUCCESS = 'CHECK_USERNAME_SUCCESS';
export const CHECK_USERNAME_FAILURE = 'CHECK_USERNAME_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';


export const TOGGLE_ACTIVATION_SCREEN = 'TOGGLE_ACTIVATION_SCREEN'
export const CLEAR_ACTIVATION_ERROR = 'CLEAR_ACTIVATION_ERROR'

export const GET_PROFILE_DATA_REQUEST = 'GET_PROFILE_DATA_REQUEST';
export const GET_PROFILE_DATA_SUCCESS = 'GET_PROFILE_DATA_SUCCESS';
export const GET_PROFILE_DATA_FAILURE = 'GET_PROFILE_DATA_FAILURE';

export const GET_MEMBERS_DATA_REQUEST = 'GET_MEMBERS_DATA_REQUEST';
export const GET_MEMBERS_DATA_SUCCESS = 'GET_MEMBERS_DATA_SUCCESS';
export const GET_MEMBERS_DATA_FAILURE = 'GET_MEMBERS_DATA_FAILURE';

export const DOWNLOAD_ATTACHMENT = 'DOWNLOAD_ATTACHMENT';

// provider directory
export const SELECT_PROVIDER = 'SELECT_PROVIDER';
export const SAVE_PROVIDER_LIST = 'SAVE_PROVIDER_LIST';
export const FILTER_PROVIDER_LIST = 'FILTER_PROVIDER_LIST';
export const CLEAR_FILTER_PROVIDER_LIST = 'CLEAR_FILTER_PROVIDER_LIST';

//MAP Location
export const GET_AREA_OF_FOCUS_REQUESTED = 'GET_AREA_OF_FOCUS_REQUESTED';
export const GET_AREA_OF_FOCUS_SUCCESS = 'GET_AREA_OF_FOCUS_SUCCESS';
export const GET_AREA_OF_FOCUS_FAILURE = 'GET_AREA_OF_FOCUS_FAILURE';
export const CLEAN_AREA_OF_FOCUS = 'CLEAN_AREA_OF_FOCUS';

export const GET_LOCATION_REQUESTED = 'GET_LOCATION_REQUESTED';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';
export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';
export const CLEAR_SELECTED_PROVIDER = 'CLEAR_SELECTED_PROVIDER';

// export const GET_MESSAGES_BY_CONVERSATIONID_REQUEST = 'GET_MESSAGES_BY_RECIPENTID_REQUEST';
// export const GET_MESSAGES_BY_RECIPENTID_SUCCESS = 'GET_MESSAGES_BY_RECIPENTID_SUCCESS';
// export const GET_MESSAGES_BY_RECIPENTID_FAILURE = 'GET_MESSAGES_BY_RECIPENTID_FAILURE';

export const GET_CONVERSATIONS_REQUEST = 'GET_CONVERSATIONS_REQUEST';
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS';
export const GET_CONVERSATIONS_FAILURE = 'GET_CONVERSATIONS_FAILURE';

export const FIND_CARE_REQUEST = 'FIND_CARE_REQUEST';
export const FIND_CARE_SUCCESS = 'FIND_CARE_SUCCESS';
export const FIND_CARE_FAILURE = 'FIND_CARE_FAILURE';
export const FIND_CARE_MORE_REQUEST = 'FIND_CARE_MORE_REQUEST';
export const FIND_CARE_MORE_HAS_DATA = 'FIND_CARE_MORE_HAS_DATA';
export const FIND_CARE_MORE_SUCCESS = 'FIND_CARE_MORE_SUCCESS';
export const FIND_CARE_MORE_FAILURE = 'FIND_CARE_MORE_FAILURE';
export const FIND_CARE_LOAD = 'FIND_CARE_LOAD';
export const FIND_CARE_CLOSE = 'FIND_CARE_CLOSE';
export const FIND_CARE_CLEAR_SEARCH = 'FIND_CARE_CLEAR_SEARCH';
export const FIND_CARE_SET_STATE = 'FIND_CARE_SET_STATE';
export const FIND_CARE_SET_SEARCH_CRITERIA = 'FIND_CARE_SET_SEARCH_CRITERIA';
export const FIND_CARE_SET_UI_STATE = 'FIND_CARE_SET_UI_STATE';


export const GET_MESSAGES_BY_CONVERSATIONID = {
  START: `GET_MESSAGES_BY_CONVERSATIONID_START`,
  SUCCEED: `GET_MESSAGES_BY_CONVERSATIONID_SUCCEEDED`,
  FAIL: `GET_MESSAGES_BY_CONVERSATIONID_FAILED`,
};

export const GET_MEMBER_MESSAGE = {
  START: `GET_MEMBER_MESSAGE_START`,
  SUCCEED: `GET_MEMBER_MESSAGE_SUCCEEDED`,
  FAIL: `GET_MEMBER_MESSAGE_FAILED`,
};

export const REPLY_MESSAGE = {
  START: `REPLY_MESSAGE_START`,
  SUCCEED: `REPLY_MESSAGE_SUCCEEDED`,
  FAIL: `REPLY_MESSAGE_FAILED`,
};

export const CREATE_CONVERSATION = {
  START: `CREATE_CONVERSATION_START`,
  SUCCEED: `CREATE_CONVERSATION_SUCCEEDED`,
  FAIL: `CREATE_CONVERSATION_FAILED`,
};

export const CHANGE_CONVERSATION_STATUS = {
  START: `CHANGE_CONVERSATION_STATUS_START`,
  SUCCEED: `CHANGE_CONVERSATION_STATUS_SUCCEEDED`,
  FAIL: `CHANGE_CONVERSATION_STATUS_FAILED`,
};

