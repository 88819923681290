import React from 'react'

const ArrowDownIcon = () => {
    return (
        <svg width="10px" height="13px" viewBox="0 0 7 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Icon/arrow-down Copy 5@1.5x</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="1.-Management-Tool-PCP" transform="translate(-872.000000, -236.000000)" fill="#7A7E81">
                    <g id="Table" transform="translate(130.000000, 223.000000)">
                        <g id="Table-Header" transform="translate(10.000000, 0.000000)">
                            <g id="Icon/directional-sort" transform="translate(730.000000, 13.000000)">
                                <path d="M5.87370466,5.92041774 L8.4269494,8.79281808 C8.61040841,8.99920946 8.59181805,9.31524565 8.38542667,9.49870466 C8.2939011,9.58006072 8.17570183,9.625 8.05324475,9.625 L2.94675525,9.625 C2.67061288,9.625 2.44675525,9.40114237 2.44675525,9.125 C2.44675525,9.00254292 2.49169454,8.88434364 2.5730506,8.79281808 L5.12629534,5.92041774 C5.30975435,5.71402636 5.62579054,5.69543599 5.83218192,5.878895 C5.84682435,5.89191049 5.86068917,5.90577531 5.87370466,5.92041774 Z" id="Triangle-Copy" transform="translate(5.500000, 7.562500) scale(1, -1) translate(-5.500000, -7.562500) "></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default ArrowDownIcon
