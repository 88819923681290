import React from 'react';

const CheckCircleIcon = ({ width = '57px', height = '55px' }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 57 55"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<title>Icon/check-circle</title>
		<defs>
			<path
				d="M6.48548581,11.9318593 C14.266484,2.82810153 27.1086465,-0.0734152921 38.0477777,4.80077675 C39.2879392,5.35336043 39.8453312,6.80666757 39.2927475,8.04682908 C38.7401638,9.2869906 37.2868567,9.84438257 36.0466951,9.29179889 C27.0964969,5.30382358 16.5892731,7.67779189 10.2230018,15.126321 C3.85673052,22.5748501 3.14796318,33.3235769 8.48105827,41.5435582 C13.8141534,49.7635395 23.9186792,53.4966395 33.3150374,50.7184298 C42.7113955,47.9402201 49.1612177,39.3125164 49.1668197,29.5154545 L49.1668197,29.5154545 L49.1668197,27.2292045 C49.1668197,25.8715045 50.2674531,24.7708712 51.6251531,24.7708712 C52.9828531,24.7708712 54.0834864,25.8715045 54.0834864,27.2292045 L54.0834864,27.2292045 L54.0834864,29.5168601 C54.0766386,41.4927648 46.1935226,52.037736 34.7090848,55.4333256 C23.2246471,58.8289152 10.8746711,54.2662375 4.35644372,44.2195936 C-2.16178362,34.1729498 -1.29551242,21.0356172 6.48548581,11.9318593 Z M52.3451822,5.65215032 C53.3052211,4.69211143 54.8617517,4.69211143 55.8217906,5.65215032 C56.7818295,6.6121892 56.7818295,8.16871977 55.8217906,9.12875866 L55.8217906,9.12875866 L28.7801239,36.1704253 C27.820085,37.1304642 26.2635545,37.1304642 25.3035156,36.1704253 L25.3035156,36.1704253 L17.9285156,28.7954253 C16.9684767,27.8353864 16.9684767,26.2788559 17.9285156,25.318817 C18.8885545,24.3587781 20.445085,24.3587781 21.4051239,25.318817 L21.4051239,25.318817 L27.0418197,30.9555128 Z"
				id="path-2"
			></path>
		</defs>
		<g id="Activity-and-EOBs-CheckCircle" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Activity---Family-1--Sync-successful-checkcircle" transform="translate(-158.000000, -382.000000)">
				<g id="Success" transform="translate(124.000000, 380.000000)">
					<g id="Icon/check-circle" transform="translate(34.000000, 0.000000)">
						<mask id="mask-2" fill="white">
							<use xlinkHref="#path-2"></use>
						</mask>
						<use id="Shape" fill="#00a79d" fillRule="nonzero" xlinkHref="#path-2"></use>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default CheckCircleIcon;
