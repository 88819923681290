import { serviceAPI } from "../../services/service.api";
import { networksSlice } from './slice'

const { setNetworks, loadNetworks } = networksSlice.actions

export const fetchNetworks = () => async (dispatch: any) => {
  dispatch(loadNetworks());

  const { data } = await serviceAPI.getClientList();

  let codes = [];
  let domainNetworks: { [key: string]: string[]} = {};
  for (const network of data) {
    if (!network.planNetworkCodes) {
      continue;
    }

    const networkCodes = network.planNetworkCodes.split(',');
    for (let code of networkCodes) {
      code = code.trim();
      if (domainNetworks[code]) {
        domainNetworks[code].push(network.providerDomainNetwork);
        continue;
      } 

      codes.push({ label: code, value: code});
      domainNetworks[code] = [network.providerDomainNetwork];
    }
  }

  codes.sort((a, b) => a.value.localeCompare(b.value));
  dispatch(setNetworks({ items: codes, domains: domainNetworks}));
}
