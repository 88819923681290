export abstract class tabIdentifiers {
    static readonly PROFILE = "profile";
    static readonly CARE = "care";
    static readonly MESSAGES = "messages";
    static readonly FAMILY = "family";
    static readonly REFERRAL = "referral";
    static readonly FIND_CARE = "findCare";
    static readonly COST_ESTIMATOR = "costEstimator";
}

export abstract class tabNames {
    static readonly PROFILE = "Profile";
    static readonly CARE = "PCP";
    static readonly MESSAGES = "Messages";
    static readonly FAMILY = "Family";
    static readonly REFERRAL = "Referral";
    static readonly FIND_CARE = "Find Care";
    static readonly COST_ESTIMATOR = "Cost Estimator";
}

export abstract class tabKeys {
    static readonly PROFILE = 1;
    static readonly CARE = 2;
    static readonly MESSAGES = 3;
    static readonly FAMILY = 4;
    static readonly REFERRAL = 5;
    static readonly FIND_CARE = 6;
    static readonly COST_ESTIMATOR = 7;
}
