import * as actionType from '../constants/constant.action'


export const getDependentList = reqObj => {
  return {
    type: actionType.GET_DEPENDENT_LIST_REQUEST,
    payload: {
      ...reqObj,
      memberUuid: window.atob(reqObj.memberUuid)
    }
  }
}


export const updateAccessApproval = reqObj => ({
  type: actionType.UPDATE_ACCESS_APPROVAL_REQUEST,
  payload: reqObj
});


export const setDependentInfo = familyMembers => ({
  type: actionType.SET_MEMBER_DEPENDENTS,
  payload: {
    familyMembers: familyMembers
  }
});

