import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { fetchReferrals } from './thunks'

const referralsAdapter = createEntityAdapter()

const initialState = referralsAdapter.getInitialState({
  referrals: {
    items: [] as any[],
    status: 'empty',
    error: {}
  },
})

export const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchReferrals.pending, (state) => {
        state.referrals.status = 'loading'
        state.referrals.error = {}
      })
      .addCase(fetchReferrals.fulfilled, (state, action) => {
        state.referrals.status = 'loading'
        state.referrals.items = action.payload
        state.referrals.error = {}
      })
      .addCase(fetchReferrals.rejected, (state, action) => {
        state.referrals.status = 'error'
        state.referrals.items = []
        state.referrals.error = action.error
      })
  },
})
