const sortServiceLines = (serviceLines) => {
  return serviceLines.sort((a, b) => {
    if (a.name < b.name)
      return -1

    if (a.name > b.name)
      return 1

    return 0
  })
}

const sortSpecialties = (specialties) => {
  return specialties.sort((a, b) => {
    if (a.specialty < b.specialty)
      return -1
    
    if (a.specialty > b.specialty)
      return 1
    
    return 0
  })
}

module.exports = {
  sortServiceLines,
  sortSpecialties
}
