import rootSaga from '../sagas';
import prodConf from './configureStore.prod';
import devConf from './configureStore.dev';

let conf;

if (process.env.NODE_ENV === 'production') {
  conf = prodConf;
} else {
  conf = devConf;
}

export const store = conf(window.__INITIAL_STATE__);
store.runSaga(rootSaga);
