import { providerClient } from "centivo-api-clients/packages/provider-client/index";
import { serviceLinesSlice } from "./slice";

const {
  setDoctorServiceLines,
  setFacilityServiceLines,
  loadDoctorServiceLines,
  loadFacilityServiceLines,
} = serviceLinesSlice.actions

export const fetchDoctorsServiceLines = () => async (dispatch: any) => {
  dispatch(loadDoctorServiceLines());

  const data = await providerClient.getServiceLines({ isCSRDoctor: true });
  const sortedData = data.sort((a: any, b: any) => a.name === b.name ? 0 : a.name < b.name ? -1 : 1);

  dispatch(setDoctorServiceLines(sortedData));
}

export const fetchFacilityServiceLines = () => async (dispatch: any) => {
  dispatch(loadFacilityServiceLines());

  const data = await providerClient.getServiceLines({ isCSRFacility: true });
  const sortedData = data.sort((a: any, b: any) => a.name === b.name ? 0 : a.name < b.name ? -1 : 1);

  dispatch(setFacilityServiceLines(sortedData));
}