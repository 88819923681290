
import * as actionType from '../constants/constant.action';
import { tabIdentifiers, tabNames, tabKeys } from '../constants/constant.tab';
import { _global } from 'helpers';

const defaultState = {
  loading: false,
  callerInfo: {},
  memberInfo: {
    isDependentType: undefined,
    isFieldUpdated: true,
    isFieldError: false,
    respMsg: undefined,
    updateSuccess: undefined,
    updateAddressFail: undefined,
    updateAddressSuccess: undefined
  },
  profile: {
    email: undefined,
    phone: undefined,
    paperlessEOBConfirmation: undefined,
    paperlessConfirmation: undefined,
    communicationPreference: {
      email: undefined,
      sms: undefined
    },
    primaryAddress: {}
  },
  emailEditFlag: false,
  tabArray: [
    {
      key: tabKeys.PROFILE,
      identifier: tabIdentifiers.PROFILE,
      name: tabNames.PROFILE,
      isActive: true,
      visible: true,
    },
    {
      key: tabKeys.FAMILY,
      identifier: tabIdentifiers.FAMILY,
      name: tabNames.FAMILY,
      isActive: false,
      visible: true
    },
    {
      key: tabKeys.CARE,
      identifier: tabIdentifiers.CARE,
      name: tabNames.CARE,
      isActive: false,
      visible: true
    },
    {
      key: tabKeys.REFERRAL,
      identifier: tabIdentifiers.REFERRAL,
      name: tabNames.REFERRAL,
      isActive: false,
      visible: true
    },
    {
      key: tabKeys.MESSAGES,
      identifier: tabIdentifiers.MESSAGES,
      name: tabNames.MESSAGES,
      isActive: false,
      visible: true
    },
    {
      key: tabKeys.FIND_CARE,
      identifier: tabIdentifiers.FIND_CARE,
      name: tabNames.FIND_CARE,
      isActive: false,
      visible: true,
    },
    {
      key: tabKeys.COST_ESTIMATOR,
      identifier: tabIdentifiers.COST_ESTIMATOR,
      name: tabNames.COST_ESTIMATOR,
      isActive: false,
      visible: true,
    }
  ],
  isResetSuccessMsg: false
};

const memberProfile = (state = defaultState, action) => {

  const setFindCareVisible = (currentState, loading) => {
    let tooltip = '';
    if (!loading && (!currentState.memberDetail || !currentState.memberDetail.networks)) {
      tooltip = 'Please refer to plan information for provider network data.'
    }
    return {
      ...currentState,
      tabArray: currentState.tabArray.map(tabArray => {
        if (tabArray.identifier === tabIdentifiers.FIND_CARE) {
          return {
            ...tabArray,
            visible: !loading && currentState.memberDetail && currentState.memberDetail.networks,
            tooltip: tooltip,
          }
        }

        if (tabArray.identifier === tabIdentifiers.COST_ESTIMATOR) {
          const isTerminated = currentState.memberDetail && currentState.memberDetail.memberStatus?.toLowerCase() === 'terminated';
          const isPlanEffective = currentState?.memberDetail?.effectiveDate && new Date(currentState.memberDetail.effectiveDate) <= new Date();

          let tooltip = ''

          if (isTerminated)
            tooltip = 'This tab is not accessible for terminated members';

          if (!isPlanEffective)
            tooltip = `This tab will be available for this member after ${_global.formatDateString(currentState?.memberDetail?.effectiveDate)}`;

          return {
            ...tabArray,
            visible: !loading && currentState.memberDetail && isPlanEffective && !isTerminated,
            tooltip: !loading ? tooltip : '',
          }
        }

        return tabArray;
      })
    }
  }

  switch (action.type) {
    case actionType.SET_LOADING:
      return setFindCareVisible({
        ...state,
        loading: action.payload.loading
      }, action.payload.loading);

    case actionType.GET_TAB_INFO:
      return {
        ...state,
        tabArray: state.tabArray.map(tabArray =>
          tabArray.identifier === action.payload.identifier
            ? {
              ...tabArray,
              isActive: true
            }
            : {
              ...tabArray,
              isActive: false
            }
        )
      };

    case actionType.RESET_TAB_INFO:
      return setFindCareVisible({
        ...state,
        tabArray: state.tabArray.map(tabArray => ({
          ...tabArray,
          visible: true
        }))}, state.loading);

    case actionType.SET_CALLER_MEMBERID:
      return {
        ...state,
        callerInfo: {
          ...state.callerInfo,
          id: action.payload.callerId
        }
      };

    // case actionType.SET_CALLER_DETAILS:
    //   return {
    //     ...state,
    //     loading:false,
    //     callerInfo: {
    //       ...state.callerInfo,
    //       details: action.payload
    //     }
    //   };

    case actionType.SET_MEMBER_MEMBERID:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          id: action.payload.memberId
        }
      };

    case actionType.SET_MEMBER_PROFILE:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          profile: action.payload.profileDetails
        }
      };

    case actionType.SET_MEMBER_DETAILS:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          details: action.payload
        }
      };

    case actionType.SET_MEMBER_ACCESS_ON_DEPENDENT:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          accessOnDependent: action.payload.accessApprovalLevel
        }
      };

    case actionType.UPDATE_TAB_INFO:
      return {
        ...state,
        tabArray: state.tabArray.map(tabArray => {
          return tabArray.identifier === action.payload.tabInfo.identifier
            ? {
              ...tabArray,
              visible: action.payload.tabInfo.visible
            }
            : tabArray;
        })
      };

    case actionType.UPDATE_WITH_NEW_MEMBER_INFO:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          isFieldUpdated: action.payload.isFieldUpdated,
          profile: {
            ...state.memberInfo.profile,
            email: action.payload.email,
            phone: action.payload.phone,
            paperlessEOBConfirmation: action.payload.paperlessEOBConfirmation,
            paperlessConfirmation: action.payload.paperlessConfirmation,
            communicationPreference: {
              ...state.memberInfo.profile.communicationPreference,
              email: action.payload.preferenceEmail,
              sms: action.payload.preferenceSms
            }
          }
        }
      };

    case actionType.NEW_FIELD_UPDATE_ERROR:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          respMsg: action.payload.respMsg
        }
      };

    case actionType.NEW_FIELD_UPDATE_SUCCESS:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          respMsg: action.payload.respMsg
        }
      };

    case actionType.NEW_UPDATE_EMAIL_ADDRESS:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          profile: {
            ...state.memberInfo.profile,
            email: action.payload.email
          }
        }
      };

    case actionType.NEW_UPDATE_PHONE_NUMBER:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          profile: {
            ...state.memberInfo.profile,
            phone: action.payload.phoneNmr
          }
        }
      };

    case actionType.NEW_UPDATE_PAPERLESS_PREFERENCE:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          profile: {
            ...state.memberInfo.profile,
            paperlessEOBConfirmation: action.payload.paperlessEOBConfirmation,
            paperlessConfirmation: action.payload.paperlessConfirmation,
          }
        }
      };

    case actionType.NEW_UPDATE_EMAIL_PREFERENCE:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          profile: {
            ...state.memberInfo.profile,
            communicationPreference: {
              ...state.memberInfo.profile.communicationPreference,
              email: action.payload.emailPreferece
            }
          }
        }
      };

    case actionType.NEW_UPDATE_SMS_PREFERENCE:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          profile: {
            ...state.memberInfo.profile,
            communicationPreference: {
              ...state.memberInfo.profile.communicationPreference,
              sms: action.payload.smsPreference
            }
          }
        }
      };


    case actionType.UPDATE_SUCCESS_MSG:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          updateSuccess: action.payload.updateSuccess
        }
      };


    case actionType.SET_DEPENDENT_TOGGLE:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          isDependentType: action.payload.eligibilityType,
          isDependentStatus: action.payload.memberStatus
        }
      };

    case actionType.RESET_ACCOUNT:
      return {
        ...state,
        isResetSuccessMsg: action.payload.isResetSuccessMsg
      };

    case actionType.RESET_MEMBER_DETAILS_REDUCER:
      return defaultState;

    case actionType.GET_MEMBER_INFO_SUCCESS:
      return setFindCareVisible({
        ...state,
        ...action.payload,
        loading: false,
        callerInfo: {
          ...state.callerInfo,
          ...action.payload.memberDetail
        }
      }, false);


    case actionType.RESET_ACCOUNT_REQUEST:
      return setFindCareVisible({
        ...state,
        loading: true
      }, true);

    case actionType.RESET_ACCOUNT_SUCCESS:
      return setFindCareVisible({
        ...state,
        loading: false
      }, false);

    case actionType.RESET_ACCOUNT_FAILURE:
      return setFindCareVisible({
        ...state,
        loading: false
      }, false);

    case actionType.UPDATE_MEMBER_INFO_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          communicationPreference: {
            ...state.profile.communicationPreference,
            ...action.payload
          }
        }
      }

    case actionType.GET_MEMBER_INFO_REQUEST:
      return setFindCareVisible({
        ...state,
        loading: true
      }, true);

    case actionType.GET_MEMBER_INFO_FAILURE:
      return setFindCareVisible({
        ...state,
        loading: false
      }, false);

    case actionType.UPDATE_MEMBER_INFO_FAILURE:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          respMsg: action.payload
        }
      }

    case actionType.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          respMsg: ''
        }
      }

    case actionType.GET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          email: action.payload.email,
          phone: action.payload.phone,
          userName: action.payload.userName
        }
      };

    case actionType.CLEAR_MEMBER_DATA:
      return defaultState

    // case actionType.CLEAR_MEMBER_SEARCH_RESULT:
    //   return defaultState

    default:
      return state;
  }
};

export default memberProfile;
