import React from 'react'
import Shimmer from './Shimmer';
import './Shimmer.scss';

const SkeletonText = ({ style = {} }) => {
    return (
        <div className='skeleton-wrapper' style={style}>
            <div className='skeleton-text'></div>
            <Shimmer />
        </div>
    )
}

export default SkeletonText
