import { ServiceLinesState } from './types'

export const doctorServiceLinesSelector = (state: any): ServiceLinesState => {
  const { items, status } = state.serviceLines.doctorServiceLines ?? { items: [], status: 'empty' }

  const data = [...(items as { name: string; isPcp: boolean }[])]
    .sort((s1, s2) => {
      return s1.name.localeCompare(s2.name)
    })
    .map((servLine: any) => {
      return {
        label: servLine.name,
        value: servLine.name
      }
    })

  return { status, items: data }
}

export const facilityServiceLinesSelector = (state: any): ServiceLinesState => {
  const { items, status } = state.serviceLines.facilityServiceLines ?? { items: [], status: 'empty' }

  const data = items.map((servLine: any) => {
    return {
      label: servLine.name,
      value: servLine.name
    }
  })

  return { status, items: data }
}
