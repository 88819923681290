import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  specialties: {
    items: [] as any[],
    status: 'empty',
  },
}

export const specialtiesSlice = createSlice({
  name: 'specialties',
  initialState,
  reducers: {
    reset: () => initialState,
    loadSpecialties: (state) => {
      state.specialties.status = 'loading'
    },
    setSpecialties: (state, action: PayloadAction<any[]>) => {
      state.specialties.items = action.payload
      state.specialties.status = 'loaded'
    },
  },
});
