import * as actionType from '../constants/constant.action'

export const getReferrals = memberId => ({
  type:actionType.GET_REFERRALS_REQUEST,
  payload:{
    memberUuid:window.atob(memberId)
  }
})

export const getServiceLines = reqObj => ({
  type:actionType.GET_SERVICE_LINES_REQUEST,
  payload:reqObj
});

export const setServiceLinesLoading = pcploading => ({
  type: actionType.SERVICE_LINES_SET_LOADING,
  payload: {
    pcploading: pcploading
  }
});


export const setReferralError = (errorCode, error) => ({
  type: actionType.SET_REFERRAL_ERROR,
  payload: {
    referralError: {
      errorCode: errorCode,
      error: error
    }
  }
});


export const setReferrals = referrals => {
  return {
    type: actionType.SET_REFERRAL_DATA,
    payload: { referrals: referrals }
  };
};

export const createSubmitReferral = requestJSON => ({
  type: actionType.SET_REFERRALS_REQUEST,
  payload: requestJSON
});

export const createReferralsFailure = createReferralsFailure => ({
  type: actionType.SET_REFERRALS_FAILURE,
  payload: { createReferralsFailure }
});

export const createReferralsSuccess = createReferralsSuccess => ({
  type: actionType.SET_REFERRALS_SUCCESS,
  payload: { createReferralsSuccess }
});

export const clearReferralsStatus = () => ({
  type: actionType.CLEAR_REFERRALS_STATUS
})

export const isPcpReferralAvailable = isPcpReferralAvailable => ({
  type: actionType.GET_PCP_REFERRALS_AVAILABLE,
  payload: { isPcpReferralAvailable }
});
