
import {call, put, takeLatest} from 'redux-saga/effects'
import { 
    GET_REFERRALS_REQUEST, 
    GET_REFERRALS_SUCCESS, 
    GET_REFERRALS_FAILURE, 
    GET_SERVICE_LINES_REQUEST,
    GET_SERVICE_LINES_SUCCESS,
    GET_SERVICE_LINES_FAILURE
} from "../constants"
import { api } from '../services'

function* getReferralsResult(action){
    try{
        const result = yield call(api.getReferrals, action.payload)
        if(result.data && result.status === 200 ){
            yield put({type: GET_REFERRALS_SUCCESS, payload:result.data})
        }
        else {
            yield put({type: GET_REFERRALS_FAILURE, payload: result.response.data})    
        }
        
    }
    catch(error){
        yield put({type: GET_REFERRALS_FAILURE, error})
    }
}

function* getServiceLinesResult(action){
    try{

        const result = yield call(api.getServiceLines, action.payload);
        if(result.data && result.status === 200 ){
            yield put({type: GET_SERVICE_LINES_SUCCESS, payload:result.data})
        }
        else {
            yield put({type: GET_SERVICE_LINES_FAILURE, payload: result.response.data.error.message})    
        }
    }
    catch(e){
        yield put({type: GET_SERVICE_LINES_FAILURE, payload: e})    
    }
}

export function* getServiceLinesWatcherSaga(){
    yield takeLatest(GET_SERVICE_LINES_REQUEST, getServiceLinesResult)
}

export function* getReferralsWatcherSaga(){
    yield takeLatest(GET_REFERRALS_REQUEST, getReferralsResult)
}