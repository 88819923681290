import React from 'react'
import './Shimmer.scss';

const Shimmer = () => {
    return (
        <div className='shimmer-wrapper'>
            <div className='shimmer'></div>
        </div>
    )
}

export default Shimmer
