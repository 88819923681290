import { reducer as reduxFormReducer } from 'redux-form'
import { combineReducers } from '@reduxjs/toolkit'
import memberProfile from './reducer.memberProfile'
import memberDependents from './reducer.memberDependents'
import memberSearch from './reducer.memberSearch'
import memberCare from './reducer.memberCare'
import memberActivation from './reducer.memberActivation'
import memberMessage from './reducer.memberMessage'
import conversations from './reducer.conversations'
import newMessage from './reducer.newMessage'
import createTemplate from './reducer.createTemplate'
import criteriaSearch from './reducer.criteriaSearch'
import messageList from './reducer.messageList'
import referralDetails from './reducer.referral'
import resourceReducer from './reducer.getResources'
import loginReducer from './reducer.login'
import memberFamilyProfile from './reducer.memberFamilyDetail'
import updateAddressReducer from './reducer.updateMailingAddress'
import providerDirectory from './reducer.providerDirectory'
import findCareDetails from './reducer.findCare'
import { facilitySlice } from '../store/facility'
import { networksSlice } from '../store/networks'
import { specialtiesSlice } from '../store/specialties'
import { serviceLinesSlice } from 'store/serviceLines'
import { providersSlice } from '../store/providers'
import { referralsSlice } from '../store/referrals'

const appReducer = combineReducers({
  memberProfile,
  memberDependents,
  memberSearch,
  memberCare,
  memberActivation,
  memberMessage,
  newMessage,
  conversations,
  createTemplate,
  criteriaSearch,
  messageList,
  referralDetails,
  memberFamilyProfile,
  resources: resourceReducer,
  form: reduxFormReducer,
  login: loginReducer,
  updateMailingAddress: updateAddressReducer,
  providerDirectory,
  findCareDetails,
  facilities: facilitySlice.reducer,
  networks: networksSlice.reducer,
  specialties: specialtiesSlice.reducer,
  serviceLines: serviceLinesSlice.reducer,
  providers: providersSlice.reducer,
  referrals: referralsSlice.reducer
})

export const reducers = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined
  }

  if (action.type === 'RESET_TO') {
    state = action.payload
  }

  return appReducer(state, action)
}
