import React from 'react'

const DownArrowIcon = () => {
    return (
        <svg width="10px" height="5px" viewBox="0 0 10 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>icon/down arrow@3x</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Message-Inbox-View-message-thread" transform="translate(-62.000000, -465.000000)">
                    <g id="table" transform="translate(42.000000, 333.000000)">
                        <g id="arrow_drop_down_black_24dp" transform="translate(13.000000, 122.000000)">
                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                            <polygon id="Path" fill="#00A69E" fillRule="nonzero" points="7 10 12 15 17 10"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default DownArrowIcon
