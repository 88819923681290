import axios from 'axios'

const providerDomainApi = axios.create({ baseURL: process.env.CAREPOINT_URL || process.env.REACT_APP_CAREPOINT_URL });

export const paginateProviderDomainRequest = async (url: string, params: any) => {
  const responseData = []

  const pageSize = 1000
  let page = 0

  let response

  do {
    response = await providerDomainApi.get(url, {
      params: {
        ...params,
        pageSize,
        page,
      }
    })

    responseData.push(...response.data.data)

    page++
  } while (response?.data?.data?.length === 1000)

  return responseData
}
