import React from 'react';

const IconDelete = ({ color = '#7A7E81', circleFill= '#7A7E81' }) => (
	<svg
		width="24px"
		height="24px"
		viewBox="0 0 20 20"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xlinkHref="http://www.w3.org/1999/xlink"
	>
		<title>Icon/Delete mobile</title>
		<defs>
			<path
				d="M10,0.833333333 C15.0626102,0.833333333 19.1666667,4.93738979 19.1666667,10 C19.1666667,15.0626102 15.0626102,19.1666667 10,19.1666667 C4.93738979,19.1666667 0.833333333,15.0626102 0.833333333,10 C0.833333333,4.93738979 4.93738979,0.833333333 10,0.833333333 Z M10,2.5 C5.85786438,2.5 2.5,5.85786438 2.5,10 C2.5,14.1421356 5.85786438,17.5 10,17.5 C14.1421356,17.5 17.5,14.1421356 17.5,10 C17.5,5.85786438 14.1421356,2.5 10,2.5 Z M11.9107443,6.91074435 C12.2361813,6.58530744 12.7638187,6.58530744 13.0892557,6.91074435 C13.4146926,7.23618126 13.4146926,7.76381874 13.0892557,8.08925565 L13.0892557,8.08925565 L11.1773333,9.99933333 L13.0892557,11.9107443 C13.3875728,12.2090615 13.4124326,12.6772833 13.1638349,13.0039179 L13.0892557,13.0892557 C12.7638187,13.4146926 12.2361813,13.4146926 11.9107443,13.0892557 L11.9107443,13.0892557 L9.99933333,11.1773333 L8.08925565,13.0892557 C7.79093848,13.3875728 7.32271667,13.4124326 6.99608206,13.1638349 L6.91074435,13.0892557 C6.58530744,12.7638187 6.58530744,12.2361813 6.91074435,11.9107443 L6.91074435,11.9107443 L8.82133333,9.99933333 L6.91074435,8.08925565 C6.61242718,7.79093848 6.58756742,7.32271667 6.83616506,6.99608206 L6.91074435,6.91074435 C7.23618126,6.58530744 7.76381874,6.58530744 8.08925565,6.91074435 L8.08925565,6.91074435 L9.99933333,8.82133333 Z"
				id="path-1"
			></path>
		</defs>
		<g id="Contact" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Contact---Main-Screen-Display-attachment" transform="translate(-114.000000, -470.000000)">
				<g id="Form/FormField/Active-Message" transform="translate(19.000000, 371.000000)">
					<g id="Icon/Delete-mobile" transform="translate(95.000000, 99.000000)">
						<circle id="Oval" fill={circleFill} cx="10" cy="10" r="8.33333333"></circle>
						<g id="Icon/x-circle">
							<mask id="mask-2" fill="white">
								<use xlinkHref="#path-1"></use>
							</mask>
							<use id="Shape" fill="#FFFFFF" fillRule="nonzero" xlinkHref="#path-1"></use>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default IconDelete;
