import React from 'react'

const ArrowUpIcon = () => {
    return (
        <svg width="10px" height="13px" viewBox="0 0 7 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Icon/arrow-down Copy 5@1.5x</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="1.-Management-Tool-PCP" transform="translate(-872.000000, -236.000000)" fill="#7A7E81">
                    <g id="Table" transform="translate(130.000000, 223.000000)">
                        <g id="Table-Header" transform="translate(10.000000, 0.000000)">
                            <g id="Icon/directional-sort" transform="translate(730.000000, 13.000000)">
                                <path d="M5.87370466,0.878751075 L8.4269494,3.75115141 C8.61040841,3.9575428 8.59181805,4.27357898 8.38542667,4.45703799 C8.2939011,4.53839405 8.17570183,4.58333333 8.05324475,4.58333333 L2.94675525,4.58333333 C2.67061288,4.58333333 2.44675525,4.35947571 2.44675525,4.08333333 C2.44675525,3.96087625 2.49169454,3.84267698 2.5730506,3.75115141 L5.12629534,0.878751075 C5.30975435,0.672359691 5.62579054,0.653769327 5.83218192,0.837228335 C5.84682435,0.850243828 5.86068917,0.864108646 5.87370466,0.878751075 Z" id="Triangle"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default ArrowUpIcon

