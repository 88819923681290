import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENV_URL,
});

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${sessionStorage.getItem("accessToken")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/";
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
