import 'babel-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import "react-datepicker/dist/react-datepicker.css";

import App from './containers/app';
import Error from './Error';
import './assets/scss/style.scss';

createRoot(document.getElementById('app')).render(
  <Error>
    <App />
  </Error>
);