import * as actionType from '../constants/constant.action';

const defaultState = {
    loading: false,
    loadingMessages: false,
    sendingMessage: false,
    sendingMessageError: false,
    error: false,
    conversations: { Items: [] },
    messages: { Items: [] }
};

const conversations = (state = defaultState, action) => {
    switch (action.type) {

        case actionType.GET_CONVERSATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                conversations: action.payload ? state.conversations : defaultState.conversations,
                error: false
            }

        case actionType.GET_CONVERSATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                conversations: { ...action.payload, fetchedAt: Date.now() },
                messages: defaultState.messages,
                error: false
            }

        case actionType.GET_CONVERSATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case actionType.GET_MESSAGES_BY_CONVERSATIONID.START:
            return {
                ...state,
                messages: action.payload ? state.messages : defaultState.messages,
                loadingMessages: true,
                error: false
            }

        case actionType.GET_MESSAGES_BY_CONVERSATIONID.SUCCEED:
            return {
                ...state,
                loadingMessages: false,
                messages: action.payload,
                error: false
            }

        case actionType.GET_MESSAGES_BY_CONVERSATIONID.FAIL:
            return {
                ...state,
                loadingMessages: false,
                error: action.payload
            }
        case actionType.REPLY_MESSAGE.START:
            return {
                ...state,
                sendingMessage: true,
                sendingMessageError: false,
            }
        case actionType.REPLY_MESSAGE.SUCCEED:
            return {
                ...state,
                sendingMessage: false,
                sendingMessageError: false,
                // sentMessage: action.payload,
                messages: { ...state.messages, Items: [{ ...action.payload, messageId: 'TBD' }, ...state.messages.Items] },
                conversations: {
                    ...state.conversations, Items: state.conversations.Items.map(conversation => {
                        if (conversation.conversationId === action.payload.conversationId) {
                            let newConversationVersion = conversation.conversationVersion
                            conversation.conversationVersion = newConversationVersion++
                        }
                        return conversation;

                    }),
                    fetchedAt: Date.now()
                }
            }
        case actionType.REPLY_MESSAGE.FAIL:
            return {
                ...state,
                sendingMessage: false,
                sendingMessageError: action.payload
            }
        case actionType.CREATE_CONVERSATION.START:
            return {
                ...state,
                sendingMessage: true,
            }
        case actionType.CREATE_CONVERSATION.SUCCEED:
            return {
                ...state,
                sendingMessage: false,
            }
        case actionType.CREATE_CONVERSATION.FAIL:
            return {
                ...state,
                sendingMessage: false,
                sendingMessageError: action.payload
            }

        case actionType.CHANGE_CONVERSATION_STATUS.START:
            return {
                ...state,
                loading: true,
            }
        case actionType.CHANGE_CONVERSATION_STATUS.SUCCEED:
            return {
                ...state,
                conversations: { ...state.conversations, Items: state.conversations.Items.filter(conversation => conversation.conversationId !== action.payload), fetchedAt: Date.now() },
                loading: false,
            }
        case actionType.CHANGE_CONVERSATION_STATUS.FAIL:
            return {
                ...state,
                loading: false,
                sendingMessageError: action.payload
            }
        default:
            return {
                ...state
            }
    }

}

export default conversations
