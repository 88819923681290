import React from 'react'

const AlertTriangleIcon = () => {
    return (
        <svg width="39px" height="34px" viewBox="0 0 39 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Icon/alert-triangle</title>
        <defs>
            <path d="M20.7450428,3.33333333 C22.4927583,3.33333333 24.1136287,4.24589665 25.0248219,5.74812154 L39.1550271,29.337806 C40.043423,30.8763051 40.0487272,32.7706733 39.1689608,34.3141232 C38.2891943,35.8575732 36.656497,36.8182805 34.8617095,36.8379067 L6.61005863,36.8379067 C4.83358867,36.8182805 3.2008914,35.8575732 2.32112493,34.3141232 C1.44135846,32.7706733 1.4466627,30.8763051 2.34859714,29.3147882 L16.4652638,5.74812154 C17.376457,4.24589665 18.9973274,3.33333333 20.7450428,3.33333333 Z M20.7450428,6.66666667 C20.1637484,6.66666667 19.6245503,6.96952199 19.3220337,7.46567979 L5.22169373,31.004674 C4.92556175,31.517507 4.92379368,32.1489631 5.21704916,32.6634464 C5.51030465,33.1779297 6.05453708,33.4981655 6.62837618,33.504674 L34.843392,33.504674 C35.4355486,33.4981655 35.979781,33.1779297 36.2730365,32.6634464 C36.566292,32.1489631 36.5645239,31.517507 36.2819305,31.0276918 L22.1700428,7.46895334 C21.8679048,6.97085444 21.3276147,6.66666667 20.7450428,6.66666667 Z M19.5665315,25.6593954 C20.2174054,25.0085216 21.2726803,25.0085216 21.9235541,25.6593954 C22.574428,26.3102692 22.574428,27.3655442 21.9235541,28.016418 C21.2726803,28.6672918 20.2174054,28.6672918 19.5665315,28.016418 C18.9156577,27.3655442 18.9156577,26.3102692 19.5665315,25.6593954 Z M20.7450428,13.5045733 C21.6655174,13.5045733 22.4117095,14.2507654 22.4117095,15.17124 L22.4117095,15.17124 L22.4117095,21.8379067 C22.4117095,22.7583813 21.6655174,23.5045733 20.7450428,23.5045733 C19.8245683,23.5045733 19.0783762,22.7583813 19.0783762,21.8379067 L19.0783762,21.8379067 L19.0783762,15.17124 C19.0783762,14.2507654 19.8245683,13.5045733 20.7450428,13.5045733 Z" id="path-1"></path>
         </defs>
         <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Care-Select-PCP-Copy-3" transform="translate(-447.000000, -67.000000)">
                <g id="Modal-Warning" transform="translate(430.000000, 48.000000)">
                    <g id="Icon/alert-triangle" transform="translate(16.000000, 16.000000)">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <use id="Shape" fill="#FFFFFF" xlinkHref="#path-1"></use>
                    </g>
                </g>
            </g>
        </g>
</svg>
    )
}

export default AlertTriangleIcon