import axios from 'axios'
import { providersSlice } from './slice'

const providerDomainApi = axios.create({ baseURL: process.env.CAREPOINT_URL || process.env.REACT_APP_CAREPOINT_URL });

interface ProviderByNpiNetworkLatLong {
  npi: string | number,
  network: string,
  lat: string | number,
  long: string | number
}

const {
  loadProvider,
  setProvider,
  clearProvider,
  reset,
} = providersSlice.actions

export const fetchProviderByNpiNetworkLatLong = (props: ProviderByNpiNetworkLatLong) => async (dispatch: any) => {
  const { npi, network, lat, long } = props
  dispatch(loadProvider())

  let providers: any[] = []

  try {
    const { data: result } = await providerDomainApi.get('provider/fetch', {
      params: {
        npi,
        network,
        lat,
        long,
        pageSize: 1000,
      }
    })

    providers = result.data
  } catch (e) {
    console.log(e)
  } finally {
    const provider = providers.length ? providers[0] : {}

    dispatch(setProvider(provider))
  }
}

export const selectProvider = (provider: any) => async (dispatch: any) => {
  dispatch(setProvider(provider))
}

export const clearSelectedProvider = () => async (dispatch: any) => {
  dispatch(clearProvider())
}

export const resetProviders = () => async (dispatch: any) => {
  dispatch(reset())
}
