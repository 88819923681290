import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { _global } from '../../helpers'

const initialState = {
  facilities: {
    items: [] as any[],
    status: 'empty',
  },
}

export const facilitySlice = createSlice({
  name: 'facility',
  initialState,
  reducers: {
    reset: () => initialState,
    loadFacilities: (state) => {
      state.facilities.status = 'loading'
    },
    setFacilities: (state, action: PayloadAction<any[]>) => {
      state.facilities.items = action.payload?.filter((facility) => !(_global.isTerminatedProvidersAndFacilities(facility.endDate)))
      state.facilities.status = 'loaded'
    },
  },
})
