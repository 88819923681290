import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { LoadingWithOveraly } from '../wrapper/loader';
import actions from './../../actions/action.conversations';
import ConversationTable from './../conversation/ConversationTable';
import ModalDialog from "../../components/modal-dialog/modalDialog";
import { Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { unstable_batchedUpdates } from 'react-dom';
import "./scss/messages.scss";
import { changeToTitleCase } from '../../utils/convertionUtils';

const MessagesComponent = ({ memberId }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [characterRequired, setCharacterRequired] = useState(false);

    const [currentConversation, setCurrentConversation] = useState(null);

    const [fullName, setFullName] = useState('');
    const [subject, setSubject] = useState('Subject');
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);

    const [filesUploadErrorDisplay, setFilesUploadErrorDisplay] = useState(false);
    const [filesDisplay, setFilesDisplay] = useState(false);

    const memberProfile = useSelector(state => state.memberProfile) || {};
    const { conversations, loading, sendingMessage, sendingMessageError } = useSelector(state => state.conversations);
    const [filter, setFilter] = useState('inbox');
    const dispatch = useDispatch();

    //close the modal once the message is
    useEffect(() => {
        if (!sendingMessage && !sendingMessageError) {

            unstable_batchedUpdates(() => {
                setMessage('');
                setFiles([]);
                setIsModalOpen(false);
                setFilesDisplay(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendingMessage]);

    //messages loaded from member search
    // useEffect(() => {
    //     if (memberId && memberProfile.memberDetail) {
    //         dispatch(actions.getConversationsByRecipientIdAction(memberProfile.memberDetail.memberUuid));
    //         setFullName(memberProfile.memberDetail.name);
    //     }

    // }, [memberProfile.memberDetail]);

    //messages loaded from messages tab
    useEffect(() => {

        if (memberId && memberProfile.memberDetail) {
            dispatch(actions.getConversationsByRecipientIdAction(memberProfile.memberDetail.memberUuid, null, filter));
            setFullName(memberProfile.memberDetail.name);
        }

        if (!memberId) {
            if (filter === 'inbox') {
                dispatch(actions.getConversationsByStatusAction('CSR_ACTION_REQUIRED', null, filter));
            } else if (filter === 'complete') {
                dispatch(actions.getConversationsByStatusAction('COMPLETE', null, filter));

            }
            // else if (filter === 'sent') {
            //     dispatch(actions.getConversationsByStatusAction('CSR_REPLIED', null, filter));

            // }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, memberProfile.memberDetail]);


    const openModal = (conversation) => {

        if (conversation) {
            if (conversation.member) {
                setFullName(changeToTitleCase(conversation.member.firstName + ' ' + conversation.member.lastName));
            }
            unstable_batchedUpdates(() => {
                setCurrentConversation(conversation);
                setSubject(conversation.topic);
                setIsModalOpen(!isModalOpen);
            })
        } else {

            unstable_batchedUpdates(() => {
                //    setFullName('');
                setCurrentConversation('');
                setSubject('Subject');
                setIsModalOpen(!isModalOpen);
            })
        }

    }

    const displayAttachmentsIconHandler = (children) => {
        unstable_batchedUpdates(() => {
            setFilesDisplay(children.component);
            setFiles(children.files);
        })
    }

    const sendMessageHandler = () => {
        if (message.length < 20) {
            setCharacterRequired('Minimum 20 character are required.')
        }
        else if (!subject) {
            setCharacterRequired('Subject is missing')
        }
        else {
            if (currentConversation) {
                dispatch(actions.replyMessageByConversationIdAction({
                    recipientId: currentConversation.recipientId, // || memberProfile.memberDetail.memberUuid || , //should be same
                    conversationId: currentConversation.conversationId,
                    message,
                    files,
                    // topic: currentConversation.topic
                    //   filter
                }, filter, memberId));
            } else {
                dispatch(actions.createConversationAction({
                    recipientId: memberProfile.memberDetail.memberUuid,
                    topic: subject,
                    message,
                    files
                }));
            }
            setFiles([])
            return 'success'
        }

    }

    const displayAttachmentsError = (children) => {
        setFilesUploadErrorDisplay(children)
    };


    if (memberId && !memberProfile.memberDetail) {
        return <LoadingWithOveraly innerStyle={{ marginTop: '20%' }} />
    }
    return (
        <div className='messages'>
            {/* {JSON.stringify(memberProfile.memberDetail)} */}
            {loading && <LoadingWithOveraly innerStyle={{ marginTop: '20%' }} />}
            <ModalDialog
                title="In-App Message"
                className='message-dialog'
                showModal={isModalOpen}
                onToggle={() => { setIsModalOpen(false); setFiles([]); setMessage(''); setFilesUploadErrorDisplay(null); setCharacterRequired(false); }}
                footerBtns={[
                    {
                        key: "send",
                        text: "Send",
                        onclick: sendMessageHandler,
                        className: "custom-round-btn btn btn-info",
                        icon: "icon icon-send",
                    },
                ]}
                footerBtnsAttachement={true}
                displayAttachmentsIcon={displayAttachmentsIconHandler}
                displayAttachmentsError={displayAttachmentsError}
                loading={sendingMessage}
            >
                <Form>
                    {characterRequired && <Alert color="danger">{characterRequired}</Alert>}
                    {sendingMessageError && <Alert color="danger">{sendingMessageError}</Alert>}
                    <FormGroup>
                        <Label for="exampleEmail">To</Label>
                        <Input
                            type="email"
                            name="emailId"
                            id="emailId"
                            value={fullName}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="subject">Subject</Label>
                        <Input
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Subject"
                            value={subject}
                            readOnly={currentConversation}
                            onChange={(e) => {
                                setSubject(e.target.value);
                            }}
                        />
                    </FormGroup>

                    {/* Display the attached files */}
                    {filesUploadErrorDisplay && filesUploadErrorDisplay}
                    {filesDisplay && filesDisplay}

                    <FormGroup>
                        <Label for="message">Message</Label>
                        <Input
                            type="textarea"
                            name="message"
                            id="message"
                            placeholder="Message"
                            value={message}
                            onChange={(e) => {
                                setCharacterRequired(false)
                                setMessage(e.target.value);
                            }}
                        />
                        <div className="isDragActiveOverlay">
                            <div className="title">Drop files here</div>
                            <div className="subtitle">File types: pdf, doc, docx, jpg, png</div>
                        </div>
                    </FormGroup>
                </Form>
            </ModalDialog>

            {memberId && <div className="new-message-btn-wrapper">
                <button
                    type="button"
                    color="info"
                    className="custom-round-btn btn btn-info"
                    onClick={() => {
                        openModal();
                    }}
                >
                    <i className="icon icon-mail" />
                    New Message
                </button>
            </div>}


            <ConversationTable conversations={conversations} member={memberId ? { ...memberProfile.profile, ...memberProfile.memberDetail } : {}} openReplyModal={openModal} filter={filter} setFilter={setFilter} />

        </div>
    )
}

export default MessagesComponent
