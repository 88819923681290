import * as actionType from '../constants/constant.action';

const defaultState = {
  loading: true,
  error: '',
  searchComplete: false,
  searchResults: [],
  facilitySearch: false,
  searching: false,
  clientName: '',
  planName: '',
  selectedDoctor: null,
  memberUuid: '',
  searchCriteria: null,
  uiState: null,
  cityStateSearch: false,
  zipSearch: true,
  defaultSearchCriteria: {
    memberUuid: '',
    zip: '12345',
    name: '',
    facilityName: '',
    practiceName: '',
    specialties: '',
    npi: '',
    tin: '',
    distance: { value: '25', label: '25 miles' },
    facilitySpecialty: '',
    city: '',
    state: undefined,
    npiTinSearch: false,
    facilitySearch: false,
    pcpFlag: false,
    unpublishedFlag: true,
  },
  defaultUiState: {
    panelFilter: false,
    publishFilter: false,
    selectedZip: '12345',
    selectedDistance: { value: '25', label: '25 miles' },
    selectedCity: '',
    selectedState: undefined,
    disableReset: true,
    disableSearch: false,
    invalidZip: false,
    invalidNpi: false,
    virtualOnlyFilter: null,
  },
};

const findCareDetails = (state = defaultState, action) => {
  switch (action.type) {
    case actionType.FIND_CARE_REQUEST:
      return Object.assign({}, state, {
        error: '',
        searchComplete: false,
        searchResults: [],
        facilitySearch: false,
        searching: true,
        selectedDoctor: null,
        zipSearch: true,
        uiState: {
          ...state.uiState,
          panelFilter: false,
          publishFilter: false,
          virtualOnlyFilter: null,
        }
      });

    case actionType.FIND_CARE_SUCCESS:
        return Object.assign({}, state, {
            searchResults: action.payload.results,
            facilitySearch: action.payload.facilitySearch,
            clientName: action.payload.clientName,
            planName: action.payload.planName,
            searchComplete: true,
            error: false,
            searching: false,
            selectedDoctor: null,
            zipSearch: action.payload.zipSearch,
        });

    case actionType.FIND_CARE_FAILURE:
        return Object.assign({}, state, {
            ...state,
            searchResults: [],
            searchComplete: true,
            error: action.payload,
            searching: false,
            selectedDoctor: null,
        });

    case actionType.FIND_CARE_MORE_REQUEST:
      return Object.assign({}, state, {
        ...state,
        selectedDoctor: null,
      });

    case actionType.FIND_CARE_MORE_HAS_DATA:
      return Object.assign({}, state, {
        ...state,
        selectedDoctor: action.payload,
      });

    case actionType.FIND_CARE_MORE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        selectedDoctor: action.payload,
      });

    case actionType.FIND_CARE_MORE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        selectedDoctor: null,
      });

    case actionType.FIND_CARE_LOAD: {
      if (action.payload.memberUuid !== state.memberUuid){
        const defaultCriteria = {
          ...defaultState.defaultSearchCriteria,
          memberUuid: action.payload.memberUuid,
          zip: action.payload.memberProfile?.profile?.permanentAddress?.zip ? action.payload.memberProfile.profile.permanentAddress.zip : '12345',
        }
        const defaultUi = {
          ...defaultState.defaultUiState,
          selectedZip: defaultCriteria.zip,
          selectedCity: action.payload.memberProfile?.profile?.permanentAddress?.city ? action.payload.memberProfile.profile.permanentAddress.city : '',
          selectedState: action.payload.memberProfile?.profile?.permanentAddress?.state ?
            { label: action.payload.memberProfile.profile.permanentAddress.state, value: action.payload.memberProfile.profile.permanentAddress.state } : undefined,
        }
        return Object.assign({}, state, {
          ...defaultState,
          memberUuid: action.payload.memberUuid,
          defaultSearchCriteria: defaultCriteria,
          defaultUiState: defaultUi,
          searchCriteria: defaultCriteria,
          uiState: defaultUi,
          loading: false,
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          loading: false,
        });
      }
    }

    case actionType.FIND_CARE_CLOSE: {
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    }

    case actionType.FIND_CARE_CLEAR_SEARCH: {
      return Object.assign({}, state, {
        ...state,
        searchComplete: false,
        searchResults: [],
        searching: false,
        facilitySearch: action.payload.facilitySearch,
        searchCriteria: action.payload.searchCriteria,
        cityStateSearch: action.payload.cityStateSearch,
        uiState: action.payload.uiState,
      });
    }

    case actionType.FIND_CARE_SET_STATE: {
      let newSearchCriteria;
      if (action.payload.cityStateSearch) {
        newSearchCriteria = {
          ...action.payload.searchCriteria,
          city: action.payload.uiState.selectedCity,
          state: action.payload.uiState.selectedState,
          zip: '',
          distance: undefined,
        }
      } else {
        newSearchCriteria = {
          ...action.payload.searchCriteria,
          city: '',
          state: undefined,
          zip: action.payload.uiState.selectedZip,
          distance: action.payload.uiState.selectedDistance,
        }
      }
      return Object.assign({}, state, {
        ...state,
        searchCriteria: newSearchCriteria,
        uiState: action.payload.uiState,
        cityStateSearch: action.payload.cityStateSearch,
      });
    }

    case actionType.FIND_CARE_SET_SEARCH_CRITERIA: {
      return Object.assign({}, state, {
        ...state,
        searchCriteria: action.payload,
      });
    }

    case actionType.FIND_CARE_SET_UI_STATE: {
      return Object.assign({}, state, {
        ...state,
        uiState: action.payload,
      });
    }

    default:
      return state;
  }
};

export default findCareDetails;
