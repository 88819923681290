import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  networks: {
    items: [] as any[],
    domains: [] as any[],
    status: 'empty',
  },
}

export const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {
    reset: () => initialState,
    loadNetworks: (state) => {
      state.networks.status = 'loading'
    },
    setNetworks: (state, action: PayloadAction<any>) => {
      state.networks.items = action.payload.items
      state.networks.domains = action.payload.domains
      state.networks.status = 'loaded'
    },
  },
});
