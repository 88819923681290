import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { Admin } from './containers/admin'
import Amplify from 'aws-amplify'
import Messages from './components/messages'
import { useDispatch } from 'react-redux'
import { resetProviders } from 'store/providers'
import { resetFacilities } from 'store/facility'
import Header from 'components/header'

const Login = lazy(() => import('./containers/login'))
const MemberSearch = lazy(() => import('./containers/memberSearch'))
const Resources = lazy(() => import('./containers/resources/resources'))
const MemberDetails = lazy(() => import('./containers/memberDetails/memberDetails'))
// const MessageList = lazy(() => import('./containers/messageList/messageList'))
const PrivateRoute = lazy(() => import('./utils/PrivateRoute'))
const ProviderDirectory = lazy(() => import('./components/providerDirectory/providerDirectory'))
const ProviderDetails = lazy(() => import('./components/providerDirectory/providerDetails'))
const UnderMaintenanceTab = lazy(() => import('./components/underMaintenance/underMaintenanceTab'))

const disableProviderSearches = false

export const Routes = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const hashData = (location.hash || '').split('#')[1]
    if (hashData && hashData.indexOf('access_token') > -1) {
      sessionStorage.setItem('userSignedIn', 'true')
    }
    if (!location.pathname.startsWith('/provider-directory')) {
      dispatch(resetFacilities())
      dispatch(resetProviders())
      window.providerDirectoryFilters = {}
    }
  }, [dispatch, location])

  return (
    <>
      <Header />
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/admin' component={Admin} />
          <PrivateRoute exact path='/member-search' component={MemberSearch} />
          <PrivateRoute exact path='/member-details/:memberId' component={MemberDetails} />
          <PrivateRoute exact path='/provider-directory' component={disableProviderSearches ? UnderMaintenanceTab : ProviderDirectory} />
          <PrivateRoute exact path='/provider-directory/:npi/:network/:lat/:long/:zipcode?' component={disableProviderSearches ? UnderMaintenanceTab : ProviderDetails} />
          <PrivateRoute exact path='/resources' component={Resources} />
          {/* <PrivateRoute exact path="/messages" component={MessageList} /> */}
          <PrivateRoute exact path='/messages' component={Messages} />
          {/* <Route exact path="/new-message" component={newMessage} /> */}
          {/* <Redirect from="/" to="/admin" /> */}
        </Switch>
      </Suspense>
    </>
  )
}

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
      responseType: 'token',

      options: {
        AdvancedSecurityDataCollectionFlag: true,
        popup: true
      }
    }
  }
})
