import { FacilityState } from './types'

export const facilitySelector = (state: any): FacilityState => {
  const { items, status } = state.facilities.facilities ?? { items: [], status: "empty"};

  // const filtered = (items || []).filter((facility: any, index: number, self: any) =>
  //     index === self.findIndex((t: any) => (
  //       t.practiceName === facility.practiceName &&
  //       t.specialtyType === facility.specialtyType &&
  //       t.address1 === facility.address1 &&
  //       t.address2 === facility.address2 &&
  //       t.phone === facility.phone
  //     )),
  // )

  return { items, status }
}
