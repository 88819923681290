import React from 'react';

const XCircleAlertIcon = ({ color = '#bbbdbf', width = '55px', height = '55px' }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 55 55"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<title>Icon/check-circle</title>
		<defs>
			<path
				d="M29.5,2.45833333 C44.4347001,2.45833333 56.5416667,14.5652999 56.5416667,29.5 C56.5416667,44.4347001 44.4347001,56.5416667 29.5,56.5416667 C14.5652999,56.5416667 2.45833333,44.4347001 2.45833333,29.5 C2.45833333,14.5652999 14.5652999,2.45833333 29.5,2.45833333 Z M29.5,7.375 C17.2806999,7.375 7.375,17.2806999 7.375,29.5 C7.375,41.7193001 17.2806999,51.625 29.5,51.625 C41.7193001,51.625 51.625,41.7193001 51.625,29.5 C51.625,17.2806999 41.7193001,7.375 29.5,7.375 Z M20.3866958,20.3866958 C21.3467347,19.4266569 22.9032653,19.4266569 23.8633042,20.3866958 L23.8633042,20.3866958 L29.4993333,26.0233333 L35.1366958,20.3866958 C36.0549939,19.4683978 37.5190424,19.4284718 38.484797,20.2669178 L38.6133042,20.3866958 C39.5733431,21.3467347 39.5733431,22.9032653 38.6133042,23.8633042 L38.6133042,23.8633042 L32.9753333,29.4993333 L38.6133042,35.1366958 C39.5316022,36.0549939 39.5715282,37.5190424 38.7330822,38.484797 L38.6133042,38.6133042 C37.6532653,39.5733431 36.0967347,39.5733431 35.1366958,38.6133042 L35.1366958,38.6133042 L29.4993333,32.9753333 L23.8633042,38.6133042 C22.9450061,39.5316022 21.4809576,39.5715282 20.515203,38.7330822 L20.3866958,38.6133042 C19.4266569,37.6532653 19.4266569,36.0967347 20.3866958,35.1366958 L20.3866958,35.1366958 L26.0233333,29.4993333 L20.3866958,23.8633042 C19.4683978,22.9450061 19.4284718,21.4809576 20.2669178,20.515203 Z"
				id="path-1"
			></path>
		</defs>
		<g id="Activity-and-EOBs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Activity---Family-1--Sync-history-Error" transform="translate(-160.000000, -362.000000)">
				<g id="Error" transform="translate(103.000000, 360.000000)">
					<g id="Icon/check-circle" transform="translate(55.000000, 0.000000)">
						<mask id="mask-2" fill="white">
							<use xlinkHref="#path-1"></use>
						</mask>
						<use id="Shape" fill="#D62020" fillRule="nonzero" xlinkHref="#path-1"></use>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default XCircleAlertIcon;
