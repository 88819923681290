export const formConstants = {
  EMPTY_EMAIL_MESSAGE: 'Please enter email.',
  EMPTY_MSG: 'No results found',
  EMPTY_PASSWORD_MESSAGE: 'Password cannot be empty.',
  FILL_LAST_NAME: 'Please fill Last Name.',
  FILL_DOB: 'Please fill valid DOB.',
  FILL_MEMBER_ID: 'Please fill Member ID',
  SSN_COUNT_FOUR: 'SSN count should be 4.',
  EMPTY_TEMPLATE_NAME: 'Please enter template name.',
  EMPTY_SUBJECT: 'Please enter subject.',
  EMPTY_MESSAGE: 'Please enter message.',
  EMPTY_TEMPLATE_LIST: 'Please Create New Template !!'
};

export const FACILITIES_DROPDOWN_OPTIONS = [
  { value: "49", label: "Ambulatory Surgical Centers" },
  { value: "A0", label: "Hospitals" },
  { value: "30", label: "Imaging Centers" },
  { value: "69", label: "Lab Centers" },
  { value: "65", label: "Physical Therapy" },
  { value: "93", label: "Urgent Care" },
];
