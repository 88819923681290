import React from 'react';

const RightArrowIcon = () => {
    return (
        <svg width="5px" height="10px" viewBox="0 0 5 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Icon/right arrow@3x</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Message-Inbox" transform="translate(-65.000000, -467.000000)">
                    <g id="arrow_right_black_24dp" transform="translate(55.000000, 460.000000)">
                        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                        <polygon id="Path" fill="#00A69E" fillRule="nonzero" points="10 17 15 12 10 7"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default RightArrowIcon
