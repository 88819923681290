import React from "react";

const AttachmentIcon = () => {
  return (
    <svg width="12px" height="20px" viewBox="0 0 12 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Icon/Attach</title>
      <g id="Contact" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact---Main-Screen-Display-attachment" transform="translate(-336.000000, -571.000000)">
          <g id="attachment-24px" transform="translate(332.000000, 571.000000)">
            <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
            <path
              d="M15.2515661,13.9993804 L5.67304997,13.9993804 C3.58337369,13.9993804 1.72366179,12.4696174 1.52369276,10.3899396 C1.29372839,8.01030823 3.16343875,6.00061956 5.5030763,6.00061956 L15.8614717,6.00061956 C17.1712688,6.00061956 18.3610844,6.94047396 18.4910643,8.2402726 C18.6410411,9.74004027 17.4712223,10.9998451 16.00145,10.9998451 L6.50292142,10.9998451 C5.9530066,10.9998451 5.5030763,10.5499148 5.5030763,10 C5.5030763,9.45008519 5.9530066,9.00015489 6.50292142,9.00015489 L15.2515661,9.00015489 C15.6615026,9.00015489 16.00145,8.66020755 16.00145,8.25027106 L16.00145,8.25027106 C16.00145,7.84033456 15.6615026,7.50038722 15.2515661,7.50038722 L6.64289973,7.50038722 C5.33310264,7.50038722 4.14328695,8.44024163 4.01330709,9.74004027 C3.86333032,11.2398079 5.0331491,12.4996128 6.50292142,12.4996128 L15.8314763,12.4996128 C17.9211526,12.4996128 19.7808645,10.9698498 19.9808335,8.89017193 C20.2107979,6.51054056 18.3410875,4.50085189 16.00145,4.50085189 L5.73304068,4.50085189 C2.86348521,4.50085189 0.293883276,6.60052662 0.0239250965,9.46008364 C-0.276028437,12.7495741 2.28357505,15.4991481 5.5030763,15.4991481 L15.2515661,15.4991481 C15.6615026,15.4991481 16.00145,15.1592008 16.00145,14.7492643 L16.00145,14.7492643 C16.00145,14.3393278 15.6615026,13.9993804 15.2515661,13.9993804 Z"
              id="Path"
              fill="#7A7E81"
              fillRule="nonzero"
              transform="translate(10.000000, 10.000000) rotate(-90.000000) translate(-10.000000, -10.000000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AttachmentIcon;
