import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { history } from '../../helpers';
import { Routes } from '../../routes';
import { store } from '../../store';
// import DevTools from './devTools';

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
      {/* <DevTools /> */}
    </Provider>
  );
}

export default App;
